<template>
    <!-- current events, Workshops & Trainings section -->
    <section class="fadeInUp mx-md-4 px-md-4 mx-1 px-1 mb-2 pt-4" id="summit" ref="summit_ref">
        <div class="container-fluid">
            <div class="widget" v-if="isVisibleWorkshop">
                <h4 class="heading font-bold">Past Workshop & Training</h4>
                <div class="row" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000"
                    data-aos-easing="ease-in-out" data-aos-mirror="false" data-aos-once="false"
                    style="margin-left: -15px; margin-top: -30px !important">
                    <div class="d-flex col-lg-4 col-md-6 col-sm-6" v-for="(summit, index) in summits" :key="index">
                        <div class="d-flex project-widge" style="margin-top: 40px" >
                            <div class="card summit-card">
                                <div>
                                    <img class="card-img-top" :src="require(`@/assets/images/summit/${summit.img_name}`)" alt="summit-image"/>
                                </div>
                                <div class="project-content">
                                    <h5 v-if="summit.link" class="summit-link">
                                        <a :href="summit.link" class="font-weight-bold" v-html="summit.title"></a>
                                    </h5>
                                    <h5 v-else class="summit-no-link">
                                        <a href="#" class="summit-no-link" v-html="summit.title"></a>
                                    </h5>
                                    <p class="workshops" v-html="summit.content"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 upcoming mx-3">
            <span>For upcoming Workshops & Training follow up on Social Media:</span>
            <br />
            <span>
                <a href="https://www.facebook.com/RWBuild" target="_blank"
                    style="text-decoration: none; color: black">Facebook</a>
                &
                <a href="https://twitter.com/RwandaBuild" target="_blank"
                    style="text-decoration: none; color: black">Twitter: @RwandaBuild</a>
            </span>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            isVisibleWorkshop: false,
            summits: [
                {
                    img_name: "Speraker-College 1.jpg",
                    title: "RWBuild Code Summit Jan 2022",
                    content:
                        "A code summit for coders, by coders, and all about the code. We invited the most significant software developers and companies to share and grow their software skills and knowledge.",
                    link: "/codesummit",
                },
                {
                    img_name: "RWBuild Summit Advertising Graphic.png",
                    title: "RWBuild Workshops Feb 2022",
                    content:
                        "The Jan Candidate Workshop is an intense 3 weeks up-skilling backend and frontend developers, The Candidate program is also job placement program for also leveling up developers by adding experiences in real projects.",
                },
                {
                    img_name: "RWBuild Summit Development Process.png",
                    title: "The Software Development Process",
                    content:
                        "A full-day workshop ran by two master project planners in software development. This workshop would help any team understand why they need a proper development process and project management.",
                },
                {
                    img_name: "RWBuild Summit Killer Pitch.png",
                    title: "How to Give a Killer Presentation",
                    content:
                        "Building and then performing a great presentation is not easy. This 3-hour workshop covers both best practices for building a presentation and how to adequately perform that great presentation. There is an extra focus on 'the pitch' for startups.",
                },
                {
                    img_name: "Workshop Graphic - PHP Swift.png",
                    title: "PHP & Swift Software Development",
                    content:
                        "Two three-week workshops. The goal was on leveling up developer knowledge and skill. A one to a three-hour training session with the rest of the day focused on completing the lessons. The focus: best practices, codding behaviors, and foundational approaches.",
                },
            ],
        };
    },
      watch: {
        isVisibleWorkshop(newValue) {
            if (newValue) {
                // Initialize Swiper when content is visible
                this.$nextTick(() => {
                    // this.setThumbsSwiper();
                });
            }
        },
    },
    mounted() {
            const sectionEl = this.$refs.summit_ref;
            if (sectionEl) {
                const observer = new IntersectionObserver(
                    (entries) => {
                        if (entries[0].isIntersecting) {
                            this.isVisibleWorkshop= true;
                            observer.disconnect(); // Stop observing once it's loaded
                        }
                    },
                    {
                        root: null, // Use the viewport
                        threshold: 0.1, // Trigger when 10% of the section is in view
                    }
                );
                observer.observe(sectionEl);
            } else {
                console.error('Failed to find section element for observation.');
            }
    },
};
</script>

<style scoped>
/* Add your styles here */
.summit-no-link {
  text-decoration: none;
  color: black;
  font-weight: 900;
  font-family: "Proxima Nova";
}

.summit-no-link:hover {
  text-decoration: none;
  color: black !important;
  font-weight: bold;
}

.summit-link {
  text-decoration: underline;
  color: #007bff !important;
}

.summit-link,
a:hover {
  text-decoration: underline;
  color: #007bff !important;
}
.workshops,
.projects {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  /* font-weight: 900; */
  font-family: "Proxima Nova";
  margin: 10px 0px;
  line-height: 1.7rem;
}
</style>
