
<template>
  <div ref="section" class="container-fluid row section">
    <div class="col-md-7 col-sm-12" v-if="isVisiblePastEvents">
      <swiper :style="{
        '--swiper-navigation-color': '#000',
        '--swiper-pagination-color': '#000',
      }" :spaceBetween="10" :navigation="false" :loop="true" :thumbs="{ swiper: thumbsSwiper }" :autoplay="{
  delay: 5500,
  disableOnInteraction: false,
}" class="mySwiper2">
        <swiper-slide v-for="(event, index) in events" :key="index">
          <!-- <div class="event col-12 bg-warning" style="padding-left:-80px!important"> -->
          <div class="col-12 event-image">
            <img :id="event.id" :data-event-id="event.id" :src="require(`@/assets/images/talk/${event.img_name}`)"
              :title="event.title" :data-event-title="event.title" :data-event-description="event.description"
              :data-event-date="event.date" alt="summit-image" class="responsive" style="width: 100% !important" />
          </div>
          <!-- </div> -->
        </swiper-slide>
      </swiper>
    </div>
    <div class="col-md-5 col-sm-12 swiper-slider-aside mt-2" v-if="isVisiblePastEvents">
      <swiper @swiper="setThumbsSwiper" :spaceBetween="10" :slidesPerView="3" :freeMode="true"
        :watchSlidesVisibility="true" :watchSlidesProgress="true" class="mySwiper">
        <swiper-slide v-for="(event, index) in events" :key="index">
          <div class="event">
            <div class="event-image mx-3">
              <img :id="event.id" :data-event-id="event.id" :src="require(`@/assets/images/talk/${event.img_name}`)"
                :title="event.title" :data-event-title="event.title" :data-event-description="event.description"
                :data-event-date="event.date" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <swiper :style="{
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      }" :spaceBetween="10" :navigation="false" :loop="true" :thumbs="{ swiper: thumbsSwiper }" :autoplay="{
  delay: 5500,
  disableOnInteraction: false,
}" class="mySwiper2">
        <swiper-slide v-for="(event, index) in events" :key="index">
          <div class="event">
            <div class="event-footer">
              <div class="event-details mx-md-3">
                <h3 class="event-title slider">{{ event.title }}</h3>
                <p class="event-description">
                  {{ event.description }}
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/thumbs/thumbs.min.css";
import SwiperCore, { Autoplay, Navigation, Thumbs } from "swiper/core";
SwiperCore.use([Autoplay, Navigation, Thumbs]);
export default {
  name: "galleryPastEvents",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      thumbsSwiper: null,
      isVisiblePastEvents: false,
      events: [
        {
          id: "1",
          img_name: "talk_5.png",
          title: "Software Launch Parties",
          description: "Software Launch Events.",
          date: "2019 June 01",
        },
        {
          id: "2",
          img_name: "talk_1.png",
          title: "Pitch Night & Mentoring Feedback",
          description:
            "	Come and pitch your projects and services - get feedback on your pitch",
          date: "2019 June 02",
        },
        {
          id: "3",
          img_name: "talk_3.png",
          title: "30 Minutes of Time Management",
          description: "A quick talk on time management and productivity.",
          date: "2019 June 03",
        },
        {
          id: "4",
          img_name: "talk_4.png",
          title: "30 Minutes of Productivity",
          description:
            "A quick talk about using tech to communicate and be more productive.",
          date: "2019 June 04",
        },
        {
          id: "5",
          img_name: "Talk Graphic 6.png",
          title: "Noodles and Code",
          description:
            "Lunch and code (often Chinese noodles). Collaborate and share best practices and code approaches.",
          date: "2019 June 05",
        },
        {
          id: "6",
          img_name: "Talk Graphic 11 - Plan Writing.png",
          title: "Project Planning and Proper Plan Writing",
          description:
            "A simple way of establishing a workable scope and building a roadmap that you can execute on. If you don't plan well, you won't build well.",
          date: "2019 June 06",
        },
        {
          id: "7",
          img_name: "talk_7.png",
          title: "How to build and give Killer Presentations",
          description: "Learn the secrets of giving a 'killer pitch.",
          date: "2019 June 07",
        },
        {
          id: "8",
          img_name: "Talk Graphic 10 - COC.png",
          title: "Culture of Communication - Business communications",
          description:
            "A talk about technology, collaboration and better communication.",
          date: "2019 June 08",
        },
        {
          id: "9",
          img_name: "Talk Graphic 2 - Advice from Techpreneur.png",
          title: "Advice from a Techpreneur",
          description: "What makes and breaks a startup.",
          date: "2019 June 09",
        },
        {
          id: "10",
          img_name: "Talk Graphic 9.png",
          title: "WWDC Events",
          description:
            "World Wide Developer Conference events and live streams.",
          date: "2020 June 22",
        },
        {
          id: "11",
          img_name: "Talk Graphic 11 - Solo Breakfast.png",
          title: "Movie Night (or Day)",
          description:
            "	Movies and other entertainment bundled with food, drinks, and fun..",
          date: "2019 June 09",
        },
        {
          id: "12",
          img_name: "party.png",
          title: "RWBuild Member Appreciation",
          description:
            "New Software Features Launch +Food and Drink+ Entertainment",
          date: "2023 June 07",
        },
      ],
    };
  },
  watch: {
    isVisiblePastEvents(newValue) {
      if (newValue) {
        // Initialize Swiper when content is visible
        this.$nextTick(() => {
          this.setThumbsSwiper();
        });
      }
    },
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },

  mounted() {
    // apply lazy loading 
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          this.isVisiblePastEvents = true;
          observer.disconnect();
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    );
    observer.observe(this.$el);
  }
};
</script>
<style scoped>
.swiper-slider-aside {
  height: 20% !important;
}

.event-title {
  line-height: 16px;
  margin-top: 6px;
  color: #696969;
  font-size: 18px;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
}

.event-description {
  font-size: 16px;
  margin-top: 4px;
  color: #696969;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;

}

.swiper-slide img {
  border-radius: 10px;
}

.mySwiper2 {
  height: 100%;
  width: 100%;
}

.mySwiper {
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 0.9;
}
</style>